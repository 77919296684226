import { Controller } from "@hotwired/stimulus";
import {post} from "@rails/request.js";
import {Offcanvas} from "bootstrap";

/**
 * Sprinkles for Media Item Form
 */
export default class MedicalFileCoursesSessionsController extends Controller {
  static targets = [];

  connect() {
    console.log("Hello, MedialFileCoursesSessionsController", this.element);
    this.element.controller = this;
  }

  show() {
  }

  disconnect() {
    console.log("=> SIGN FORM: BYE");
  }
}
