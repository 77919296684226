import {Controller} from "@hotwired/stimulus";
import {Toast} from "bootstrap";

/**
 * Sprinkles for Media Item Form
 */
export default class ToastController extends Controller {
  static targets = [];

  get container() {
    return document.getElementById('toast-container');
  }

  connect() {
    console.log("Hello, toast", this.element);
    this.buildToast(
      this.buildContent()
    );
  }

  disconnect() {
    console.log('Remove');
  }

  buildContent() {
    const toastContainer = document.createElement('div');
    toastContainer.classList.add('toast', 'my-1')

    const toastType = this.element.dataset.toastType;
    const message = this.element.dataset.toastMessage;
    const title = toastType == 'success' ? 'Aviso' : 'Error';

    toastContainer.innerHTML = `
      <div class="toast-header bg-${toastType}">
        <strong class="me-auto">${title}</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">
        ${message}
      </div>
    `;

    return toastContainer;
  }

  buildToast(toastContainer) {
    this.toast = new Toast(toastContainer, {});

    toastContainer.addEventListener('hidden.bs.toast', () => {
      // do something...
      this.toast.dispose();
    });

    if (this.container == null) {
      throw `
      Toast Container not found. In order to make Toast works
      add the following div in your root template (for example: 'app/views/layouts/application.html.erb')

      <div id="toast-container" data-turbo-permanent
         class="toast-container position-absolute bottom-0 end-0 p-3"></div>
      `;

      this.toast.dispose();
    }

    this.container.appendChild(toastContainer);

    console.log("Showing toast", toastContainer);
    this.toast.show();

  }

}
