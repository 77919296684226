import {Controller} from "@hotwired/stimulus";

/**
 * Sprinkles for Media Item Form
 */
export default class ScrollWindowHereController extends Controller {
  static targets = [];

  connect() {
    console.log("Hello, scroll here", this.element);
    setTimeout(() => {
      this.element.scrollIntoView();
    });
  }

  disconnect() {
  }
}
