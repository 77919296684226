import {Controller} from "@hotwired/stimulus";

export default class ShellController extends Controller {
  static targets = [];

  connect() {
    // console.log("Hello, Shell", this.element);

    this.btnMenu && this.btnMenu.addEventListener('click', (event) => {
      this.toggleMenu();
    }, false);

    this.backdrop.addEventListener('click', (event) => {
      this.toggleMenu();
    }, false);

    this.sidebar.addEventListener('click', (event) => {
      if (event.target.tagName == "A" && event.target.classList.contains('list-group-item-action')) {
        // event.stopPropagation();
        this.toggleMenu();
      }

      if (event.target.tagName == "IMG" && event.target.id == "fitpass-logo") {
        event.stopPropagation();
        event.preventDefault();
        this.toggleMenu();
        setTimeout(() => {
          event.target.parentNode.click();
        }, 500);
      }

      // console.log('=> TARGET', event.target);
    }, false);

    // this.fixVirtualHeight();
    this.setUpSubmitOnChange();
  }

  setUpSubmitOnChange() {
    const forms = document.querySelectorAll('form[data-submit-on-change=true]');
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      console.log('Submit on change', form);

      form.addEventListener('change', () => {
        form.submit();
      });
    }
  }

  fixVirtualHeight() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }

  get btnMenu() {
    return this.element.querySelector('.btn-menu');
  }

  get backdrop() {
    return this.element.querySelector('#shell-backdrop');
  }

  get sidebar() {
    return this.element.querySelector('#sidebar');
  }

  toggleMenu() {
    this.element.classList.toggle('open');
  }
}
