import { Controller } from "@hotwired/stimulus";
import {post} from "@rails/request.js";
import {Offcanvas} from "bootstrap";

/**
 * Sprinkles for Media Item Form
 */
export default class PlaylistsShowController extends Controller {
  static targets = [];

  connect() {
    this.playlistId = this.element.dataset.playlistId;
    console.debug('Playlists#show ', this.element.dataset, this.playlistId);
    this.element.controller = this; // Access the controller via DOM

    if (this.playlistId == null) {
      throw `Playlist Id is required, please add the data attribute to the html element: data-playlist-id="..."`;
    }
  }

  addVideoToPlaylist(event) {
    console.log("Video selected", event.detail);

    const {id, type} = event.detail;

    post(`/admin/playlist_items`, {
      responseKind: 'turbo',
      body: JSON.stringify({
        courses_playlist_item: {
          contentable_type: type,
          contentable_id: id,
          courses_playlist_id: this.playlistId,
        }
      })
    }).then(result => {
      console.log(result);
    }).catch(e => {
      console.error(e);
    });
  }

  // initEvents() {
  //   this.beforeRequestHandler = this.onBeforeFetchRequest.bind(this);
  //   window.addEventListener(
  //     "turbo:before-fetch-request",
  //     this.beforeRequestHandler
  //   );
  // }
  //
  // disconnect() {
  //   console.log("=> SIGN FORM: BYE");
  //
  //   // window.removeEventListener(
  //   //   "turbo:before-fetch-request",
  //   //   this.beforeRequestHandler
  //   // );
  // }
  //
  // onBeforeFetchRequest(event) {
  //   console.log("=> FORM: Before fetch request", event.detail);
  //   // event.detail.fetchOptions.method == "GET" ||
  //   // event.detail.fetchOptions.body == null
  // }
}
