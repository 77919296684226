import {Controller} from "@hotwired/stimulus"

import {Modal, Offcanvas} from "bootstrap";

export default class extends Controller {
  static values = {
    autoShow: Boolean,
    modalType: String
  };
  static targets = [];

  connect() {
    if (this.modalTypeValue === 'offcanvas') {
      this.modal = new Offcanvas(this.element, {backdrop: 'static'});
    } else {
      this.modal = new Modal(this.element, {backdrop: 'static'});
    }

    if (this.autoShowValue) {
      this.modal.show();
    }
  }

  open(event) {
    if (!this.modal.isOpened) {
      this.modal.show();
    }
  }

  hide() {
    this.modal.hide();
  }

  /**
   * Called when the event hidden.bs.offcanvas or hidden.bs.modal is triggered
   */
  modalHidden(event) {
    this.modal.dispose();
    this.element.remove();

    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) {
      backdrop.remove();
    }
  }

  close(event) {
    if (event.detail.success) {
      this.modal.hide();
    }
  }
}

