import { Controller } from "@hotwired/stimulus";

/**
 * Sprinkles for Media Item Form
 */
export default class MediaItemFormController extends Controller {
  static targets = [];

  /**
   *
   * @returns {HTMLFormElement}
   */
  get form() {
    return this.element;
  }

  get urlFieldContainer() {
    return this.form.querySelector('#url-field-container');
  }

  get fileFieldContainer() {
    return this.form.querySelector('#file-field-container');
  }

  /**
   * @returns {HTMLSelectElement}
   */
  get mediaTypeInput() {
    return this.form['media_item[media_type]'];
  }

  connect() {
    console.log("Hello, form", this.mediaTypeInput);

    this.setUpMediaTypeControls();

    this.mediaTypeInput.addEventListener('change', () => {
      this.setUpMediaTypeControls();
    }, false);
  }

  setUpMediaTypeControls() {
    console.log("Setting up", this.mediaTypeInput.value);
    if (this.mediaTypeInput.value === 'youtube') {
      this.urlFieldContainer.style.display = 'flex';
      this.fileFieldContainer.style.display = 'none';
    } else {
      this.urlFieldContainer.style.display = 'none';
      this.fileFieldContainer.style.display = 'flex';
    }
  }
}
