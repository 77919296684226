import {LitElement, html, css} from 'lit';

export class AppPreview extends LitElement {
  static styles = css`
    :host { 
      display: inline-flex;
    }
    
    #background {
      display: flex;
      width: 320px; 
      height: 670px;
      padding: 20px 20px 23px 23px;
      /* background-color: lightgreen; */
      background-position: top left;
      background-size: contain;
      background-repeat: no-repeat;
    }
    
    iframe {
      flex-grow: 1;
      border-radius: 33px;
      border: 0;
      margin-right: 6px;
    }
  `;

  static properties = {
    src: {},
    imageUrl: {},
  };

  get iframe() {
    return this.renderRoot.querySelector('iframe');
  }

  firstUpdated(_changedProperties) {
    // super.firstUpdated(_changedProperties);
    this.iframe.setAttribute('src', this.src);
  }

  refresh() {
    this.iframe.contentWindow.location.reload();
  }

  render() {
    // const blue = '#235893';
    const blue = '#0E0F2A';
    return html`
      <div id="background" style="background-image: url('${this.imageUrl}')">
        <iframe style="background-color: ${blue}"></iframe>
      </div>
    `;
  }

}

customElements.define('s-app-preview', AppPreview);
