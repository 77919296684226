import { Controller } from "@hotwired/stimulus";
import {post} from "@rails/request.js";
import {Offcanvas} from "bootstrap";

/**
 * Sprinkles for Media Item Form
 */
export default class ContentItemSelectorController extends Controller {
  static targets = ['offcanvas', 'title'];

  connect() {
    console.log("Hello, content item selector", this.element);
    this.offcanvas = new Offcanvas(this.offcanvasTarget);
    this.element.controller = this; // Access the controller via DOM
    this.lastSubmission = undefined;
    this.currentLectureId = undefined;
    this.currentCourseId = undefined;
  }

  newContent(courseId, lectureId) {
    this.currentCourseId = courseId;
    this.currentLectureId = lectureId;
    this.lastSubmission = undefined;

    return new Promise((resolve) => {
      console.debug("Select image for lecture ", lectureId, " course", courseId);
      this.offcanvas.show();

      const listener = () => {
        console.log("Close Sesame");

        this.offcanvas._element.removeEventListener('hidden.bs.offcanvas', listener)
        resolve(this.lastSubmission);
      }

      this.offcanvas._element.addEventListener('hidden.bs.offcanvas', listener)
    });
  }

  hideFrames() {
    this.turboFrameVideos.classList.add('d-none');
    this.turboFrameExternalVideos.classList.add('d-none');
    this.turboFrameImages.classList.add('d-none');
  }

  get turboFrameVideos() {
    return this.element.querySelector('#videos');
  }

  get turboFrameExternalVideos() {
    return this.element.querySelector('#external_videos');
  }

  get turboFrameImages() {
    return this.element.querySelector('#images');
  }

  newImage(courseId, lectureId) {
    this.titleTarget.textContent = "Agregar Imagen";
    this.hideFrames();
    this.turboFrameImages.classList.remove('d-none');
    return this.newContent(courseId, lectureId);
  }

  newVideo(courseId, lectureId) {
    this.titleTarget.textContent = "Agregar Video";
    this.hideFrames();
    this.turboFrameVideos.classList.remove('d-none');
    return this.newContent(courseId, lectureId);
  }

  newExternalVideo(courseId, lectureId) {
    this.titleTarget.textContent = "Agregar Youtube Video";
    this.hideFrames();
    this.turboFrameExternalVideos.classList.remove('d-none');
    return this.newContent(courseId, lectureId);
  }

  contentItemSelected(event) {
    console.log("Content Item selected", event.params, event.detail);

    const {id, type} = event.detail;
    const lectureId = this.currentLectureId;

    post(`/admin/courses/${this.currentCourseId}/add_content_item`, {
      responseKind: 'turbo',
      body: JSON.stringify({
        courses_content_item: {
          contentable_type: type,
          contentable_id: id,
          courses_lecture_id: lectureId,
        }
      })
    }).then(result => {
      console.log(result);
      this.lastSubmission = true;
      this.offcanvas.hide();
    }).catch(e => {
      console.error(e);
    });
  }

  initEvents() {
    this.beforeRequestHandler = this.onBeforeFetchRequest.bind(this);
    window.addEventListener(
      "turbo:before-fetch-request",
      this.beforeRequestHandler
    );
  }

  disconnect() {
    console.log("=> SIGN FORM: BYE");

    // window.removeEventListener(
    //   "turbo:before-fetch-request",
    //   this.beforeRequestHandler
    // );
  }

  onBeforeFetchRequest(event) {
    console.log("=> FORM: Before fetch request", event.detail);
    // event.detail.fetchOptions.method == "GET" ||
    // event.detail.fetchOptions.body == null
  }
}
