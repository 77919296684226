import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="registration-form"
export default class RegistrationFormController extends Controller {
  connect() {
  }

  get form() {
    return this.element;
  }

  get addressField() {
    return this.form['admin_patient_registration[address]'];
  }

  get billingAddressField() {
    return this.form['admin_patient_registration[billing_address]'];
  }

  get zipcodeField() {
    return this.form['admin_patient_registration[zipcode]'];
  }

  get billingZipcodeField() {
    return this.form['admin_patient_registration[billing_zipcode]'];
  }

  get cityField() {
    return this.form['admin_patient_registration[city]'];
  }

  get billingCityField() {
    return this.form['admin_patient_registration[billing_city]'];
  }

  get stateField() {
    return this.form['admin_patient_registration[state]'];
  }

  get billingStateField() {
    return this.form['admin_patient_registration[billing_state]'];
  }

  copyAddress() {
    this.billingAddressField.value = this.addressField.value;
    this.billingZipcodeField.value = this.zipcodeField.value;
    this.billingCityField.value = this.cityField.value;
    this.billingStateField.value = this.stateField.value;
  }
}
