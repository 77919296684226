import { Controller } from "@hotwired/stimulus";

export default class PostFormController extends Controller {
  static targets = [];


  /**
   * @return {HTMLFormElement}
   */
  get form() {
    return this.element;
  }

  connect() {
    console.log("Hello, form", this.form);
  }

  get publishedAtField() {
    return this.form["admin_post_published_at"];
  }

  clearPublishedAtField() {
    this.publishedAtField.value = null;
  }

  disconnect() {
  }
}
