import { Controller } from "@hotwired/stimulus";
import {post} from "@rails/request.js";
import {Offcanvas} from "bootstrap";

/**
 * Sprinkles for Media Item Form
 */
export default class CoursesEditorController extends Controller {
  static targets = ['contentItemSelector', 'appPreview'];

  connect() {
  }

  get contentItemSelector() {
    return this.contentItemSelectorTarget.controller;
  }

  addNewImage(event) {
    console.debug("New Image: ", event.params, event.detail);
    const {lectureId, courseId} = event.params;
    this.contentItemSelector.newImage(courseId, lectureId)
      .then((result) => {
        console.log("Image added ? ", result);
      });
  }

  addNewVideo(event) {
    console.debug("New Video: ", event.params, event.detail);
    const {lectureId, courseId} = event.params;
    this.contentItemSelector.newVideo(courseId, lectureId)
      .then((result) => {
        console.log("Video added ? ", result);
      });
  }

  addNewExternalVideo(event) {
    console.debug("New External Video: ", event.params, event.detail);
    const {lectureId, courseId} = event.params;
    this.contentItemSelector.newExternalVideo(courseId, lectureId)
      .then((result) => {
        console.log("External Video added ? ", result);
      });
  }

  refreshAppPreview() {
    this.appPreviewTarget.refresh();
  }

  initEvents() {
    this.beforeRequestHandler = this.onBeforeFetchRequest.bind(this);
    window.addEventListener(
      "turbo:before-fetch-request",
      this.beforeRequestHandler
    );
  }

  disconnect() {
    console.log("=> SIGN FORM: BYE");

    window.removeEventListener(
      "turbo:before-fetch-request",
      this.beforeRequestHandler
    );
  }

  onBeforeFetchRequest(event) {
    console.log("=> FORM: Before fetch request", event.detail);
    // event.detail.fetchOptions.method == "GET" ||
    // event.detail.fetchOptions.body == null
  }
}
