import {Controller} from "@hotwired/stimulus";
import SignaturePad from "signature_pad";

/**
 * Sprinkles for Media Item Form
 */
export default class MedicalFileFormController extends Controller {
  static targets = [];

  /**
   *
   * @returns {HTMLFormElement}
   */
  get canvas() {
    return this.element.querySelector('canvas');
  }

  connect() {
    console.log(
      "Hello, Medical File form", 'LAST FAILED',
      this.lastRequestFailed,
      this.backupDataURL != null
    );
    this.injuryImageBlob = undefined;

    this.pad = new SignaturePad(this.canvas, {
      onEnd: (_event) => {
        console.log(this.pad.toData());
        this.saveBlob();
      }
    });

    // window.addEventListener("resize", this.resizeCanvas.bind(this));
    //  this.resizeCanvas();
    this.initEvents();
    this.initImage();
  }

  saveBlob() {
    this.canvas.toBlob((blob) => {
      this.injuryImageBlob = blob;
    });
  }

  restoreFromBackup() {
    this.bodyImage = new Image();
    this.bodyImage.setAttribute('src', this.backupData); // restore from DataStorage

    setTimeout(() => {
      this.draw();
      this.saveBlob();
      console.warn('=> Image drawn from backup');

      // Remove data from localstorage
      this.lastRequestFailed = false;
      this.removeBackupData();

    });
  }

  // Because we can use webpacker here without errors
  initImage() {
    this.originalImage = this.element.querySelector('#body-img');
    // EDITION IMAGE
    this.injuryImage = this.element.querySelector('#injury-image');


    if (this.lastRequestFailed) {
      this.restoreFromBackup();
      return;
    }

    this.bodyImage = this.injuryImage == null ? this.originalImage : this.injuryImage;
    this.bodyImage.setAttribute('crossorigin', 'anonymous');

    this.bodyImage.onload = () => {
      console.log("=> Image Loaded", this.bodyImage);
      this.draw();
      this.saveBlob();
    };

    if (this.bodyImage.complete) {
      console.log("=> Image Already Loaded", this.bodyImage);
      this.draw();
      this.saveBlob();
    }

    console.log("=> Loading image", this.bodyImage, 'completed', this.bodyImage.complete);
  }

  draw() {
    const context = this.canvas.getContext("2d");
    context.drawImage(this.bodyImage, 0, 0, this.canvas.width, this.canvas.height);
  }

  clear() {
    console.log('=> Clearing', this.bodyImage);
    this.pad.clear();
    const context = this.canvas.getContext("2d");
    context.drawImage(this.originalImage, 0, 0, this.canvas.width, this.canvas.height);
    this.saveBlob();
  }

  initEvents() {
    this.beforeRequestHandler = this.onBeforeFetchRequest.bind(this);
    this.beforeResponseHandler = this.onBeforeFetchResponse.bind(this)

    window.addEventListener('turbo:before-fetch-request', this.beforeRequestHandler);
    window.addEventListener('turbo:before-fetch-response', this.beforeResponseHandler);
  }

  onBeforeFetchRequest(event) {
    console.log('=> MEDICAL FILE: Before fetch request', event.detail);

    if (event.detail.fetchOptions.method == 'GET' || event.detail.fetchOptions.body == null) {
      return;
    }

    // @type {FormData}
    this.backupData = this.pad.toDataURL();
    const formData = event.detail.fetchOptions.body;
    formData.set('medical_file[injury_image]', this.injuryImageBlob);
  }


  set backupData(dataUrl) {
    localStorage.setItem('medical_file_form.data', dataUrl);
  }

  get backupData() {
    return localStorage.getItem('medical_file_form.data');
  }

  removeBackupData() {
    return localStorage.removeItem('medical_file_form.data');
  }

  get lastRequestFailed() {
    return localStorage.getItem('medical_file_form.failed') === 'true';
  }

  set lastRequestFailed(value) {
    localStorage.setItem('medical_file_form.failed', value);
  }


  onBeforeFetchResponse(event) {
    console.log('=> MEDICAL FILE: Before fetch response', event.detail);

    if (event.detail.fetchResponse.response.status == 200) {
      this.lastRequestFailed = false;
      this.removeBackupData();
    } else {
      this.lastRequestFailed = true;
    }
  }

  disconnect() {
    console.log('=> MEDICAL FILE: BYE');
    // window.removeEventListener('resize', this.resizeCanvas)
    window.removeEventListener('turbo:before-fetch-request', this.beforeRequestHandler);
    window.removeEventListener('turbo:before-fetch-response', this.beforeResponseHandler);
  }

  resizeCanvas() {
    const canvas = this.canvas;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
    if (this.pad.isEmpty()) {
      this.pad.clear(); // otherwise isEmpty() might return incorrect value
    }
  }

}
