// Entry point for the build script in your package.json
import * as bootstrap from "bootstrap"

import {Turbo} from "@hotwired/turbo-rails"

// Feels better to see something is happening
Turbo.setProgressBarDelay(100);

require("@rails/activestorage").start();
// require("channels");
import "./controllers";
import "trix";
import "@rails/actiontext";
require("@rails/ujs").start();

import "./components/app_preview";

window.addEventListener('turbo:load', ()=> {
  console.log("=> Turbo LOADED");
});
