import {Controller} from "@hotwired/stimulus";

/**
 */
export default class SelectionableController extends Controller {
  static targets = [];

  connect() {
  }

  disconnect() {
  }

  onClick(event) {
    console.debug('Item has been selected', event.params);
    this.element.dispatchEvent(
      new CustomEvent(
        'selectionable:selected',
        {detail: event.params, bubbles: true}
      )
    );
  }
}
